import React from "react";
import { FaStar } from "react-icons/fa";

export default function HelpView(): JSX.Element {
    return (
        <div className="mt-5 pt-5 border-t border-dashed border-slate-400">
            <main className="flex content-start items-center text-slate-50 px-4 py-2 h-full mx-2 my-2">
                <section className="text-left flex-col w-1/2 pr-4 space-y-2">
                    <div className="flex items-center text-2xl border-b-2 border-slate-400 uppercase font-extrabold gap-2">
                        <h1>How to use MintyGen</h1>
                        <span className="text-yellow-200">
                            <FaStar />
                        </span>
                    </div>
                    <p>
                        This section will be a full step-by-step guide on how to use the
                        MintyGen Compiler & Engine. Whats the difference? The{" "}
                        <strong className="text-blue-300">Compiler</strong> is the
                        application you are using right now (mintygen.com/app) to compile the
                        collection's configuration data. The{" "}
                        <strong className="text-blue-300">Engine</strong> is a seperate
                        piece of software you need to download. The Engine is where you
                        paste the configuration file in order to render your collection.
                    </p>

                    <div className="pt-4">
                        <h2 className="text-blue-300 font-bold">Step 1: Setting up NFT Information</h2>
                        <p className="opacity-60">
                            Begin by navigating to the "configurations" tab to input all your desired NFT information. This includes factors like baseUri, starting count, layer order, and rarity names with corresponding percentage occurrences.
                        </p>
                        <ul className="list-disc list-inside">
                            <li>The baseUri is a necessary field but can be any value for now.</li>
                            <li>The starting count is the number at which you want your NFTs to begin, either 0 or 1.</li>
                            <li>The layers are ordered based on the z-index. For instance, the background will be drawn first.</li>
                            <li>Each rarity should be given a name and a percentage occurrence. Remember, the total percentages of all rarities must equal 100% or the system will return an error.</li>
                            <li>You have the option to change the network to Solana, however, you'll need to provide additional information to do so.</li>
                        </ul>
                    </div>
                    <div className="pt-4">
                        <h2 className="text-blue-300 font-bold">Step 2: Downloading and Organizing Files</h2>
                        <p className="opacity-60">
                            Firstly, download the configurations file (config.json) by clicking on the gray download button located at the bottom of the screen.
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Then, download the engine software by pressing the yellow engine icon on the left side of your screen.</li>
                            <li>Once both files are downloaded, drag and drop the config.json file into the mintygen-engine directory.</li>
                        </ul></div>
                    <div className="pt-4">
                        <h2 className="text-blue-300 font-bold">Step 3: Sorting Your Layers</h2>
                        <p className="opacity-60">
                            Now, you'll need to organize your layers into the appropriate folders: C, U, R, SR, found under the '/layers' directory.
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Each rarity will spawn a specific attribute. For example, if you don't want the commons & uncommons to possess an ultra rare santa hat, then remove it from the 'C' & 'U' folders, leaving it under 'R' & 'SR'.</li>
                        </ul>
                    </div>
                </section>
                <section className="text-left flex-col w-1/2 pl-4 space-y-2">
                    <div>
                        <h2 className="text-blue-300 font-bold">Step 4: Installing Dependencies and Running the Program</h2>
                        <p className="opacity-60">
                            Start by installing the latest version of nodejs (https://nodejs.org/en).
                        </p>
                        <ul className="list-disc list-inside">
                            <li>After installation, run the file 'Run.bat' (Windows) or 'Run.sh' (MacOS & Linux) to install the necessary dependencies.</li>
                            <li>This will initiate by running npm install. Then, choose the 'generate' option to create your collection based on your preset configurations.</li>
                            <li>The generated metadata and images will be located in the '/build' folder.</li>
                            <li>Following this, choose the 'Shuffle' option. This will randomize the order of your collection, providing a unique mix and match outcome.</li>
                        </ul>
                    </div>
                    <div className="pt-4">
                        <h2 className="text-blue-300 font-bold">Step 5: Uploading and Updating Your NFT Art</h2>
                        <p className="opacity-60">
                            Start by uploading your images to IPFS or any other service of your preference.
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Next, choose the 'Update' option. This is where you can update the baseUri. Paste the IPFS link of your images here.</li>
                            <li>Following this, upload your metadata to IPFS.</li>
                            <li>Congratulations! Your NFT art is now ready to go.</li>
                        </ul>
                    </div>
                </section>
            </main>
        </div>
    );
}
