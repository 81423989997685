import React from "react";
import {
    FaArrowDown,
    FaArrowUp,
    FaDownload,
    FaPlusSquare,
    FaTrash,
    FaStar,
    FaHandshake,
    FaSignature,
    FaHandSparkles,
} from "react-icons/fa";
import { version } from "../../pages/Program";
import "../../css/Config.css";

const configIcon = "/images/config.svg";
const layerIcon = "/images/layer.svg";
const enterIcon = "/images/enter.png";

const networksList = [
    {
        label: "Ethereum",
        value: "Ethereum",
    },
    {
        label: "Solana",
        value: "Solana",
    },
];

const default_title = "Minty City";
const default_desc =
    "Minty City is a randomly generated NFT collection with 10 unique minties living on the blockchain.";
const default_network = "Ethereum";
const default_creators = [
    {
        address: "0x4C02541751b2eFD4F2DdBf7c86E8d91a1e41bc65",
        share: 100,
    },
    {
        address: "0x41A6f501D4F0aC17D3E2dfa971312ceC0f882Bd0",
        share: 100,
    },
];
const default_external_url = "https://mintygen.com";
const default_seller_fee_basis_points = 1000;
const default_symbol = "MC";
const default_starting_count = 0;
const default_base_uri =
    "ipfs://QmS3mMjAF2wySokRjYFVQQVFC4mX6GWCtD89uyecs3e5Eu";
const default_quantity = 10;
const default_resolution_px = {
    width: 416,
    height: 416,
};

const default_rarity_names = {
    '1': "Common",
    '2': "Uncommon",
};

const default_percentages = {
    '1_percent': 40,
    '2_percent': 60,
};

const default_layers = [
    { name: "Background", order: 0 },
    { name: "Body", order: 1 },
    { name: "Eyes", order: 2 },
    { name: "Hats", order: 3 }
];

const default_collection = [
    { name: "Minty City", family: "MintyGen" }
];

class Config extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            network: localStorage.getItem("network"),
            layers: [],
            itemName: "",
            creatorList: [],
            addressName: "",
            rarities: this.getRaritiesFromLocalStorage(),
            collection: [],
        };

        console.log(this.state.rarities)

        const items = [
            { key: "layers", default: default_layers },
            { key: "compiler_version", default: version },
            { key: "title", default: default_title },
            { key: "desc", default: default_desc },
            { key: "network", default: default_network },
            { key: "creators", default: default_creators },
            {
                key: "seller_fee_basis_points",
                default: default_seller_fee_basis_points,
            },
            { key: "external_url", default: default_external_url },
            { key: "symbol", default: default_symbol },
            { key: "collection", default: default_collection },
            { key: "starting_count", default: default_starting_count },
            { key: "baseUri", default: default_base_uri },
            { key: "quantity", default: default_quantity },
            { key: "width", default: default_resolution_px.width },
            { key: "height", default: default_resolution_px.height },
        ];

        if (!localStorage.getItem("defaults_initialized")) {
            Object.keys(default_rarity_names).forEach(key => {
                const nameKey = key;
                const percentageKey = `${key}_percent`;

                if (!localStorage.getItem(nameKey)) {
                    localStorage.setItem(nameKey, default_rarity_names[key]);
                }
                if (!localStorage.getItem(percentageKey)) {
                    localStorage.setItem(percentageKey, default_percentages[`${key}_percent`]);
                }
            });

            localStorage.setItem("defaults_initialized", "true");
        }

        for (const item of items) {
            if (localStorage.getItem(item.key) == null) {
                if (item.key === "layers") {
                    localStorage.setItem(item.key, JSON.stringify(item.default));
                } else {
                    localStorage.setItem(item.key, item.default);
                }
            }
        }
        for (const item of items) {
            if (localStorage.getItem(item.key) == null) {
                if (item.key === "collection") {
                    localStorage.setItem(item.key, JSON.stringify(item.default));
                } else {
                    localStorage.setItem(item.key, item.default);
                }
            }
        }
        for (const item of items) {
            if (localStorage.getItem(item.key) == null) {
                if (item.key === "creators") {
                    localStorage.setItem(item.key, JSON.stringify(item.default));
                } else {
                    localStorage.setItem(item.key, item.default);
                }
            }
        }
    }

    clickSetup = () => {
        const keys = Object.keys(localStorage);
        const data = [];
        keys.forEach((key) => {
            if (key !== "account") {
                const item = {
                    key: key,
                    value: localStorage.getItem(key),
                };
                data.push(item);
            }
        });

        const json = JSON.stringify(data, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "config.json";
        a.click();
        URL.revokeObjectURL(url);
    };

    handleReset = () => {
        this.setState({
            setupIsValid: false,
            layers: default_layers,
            collection: default_collection,
            creatorList: default_creators,
        });

        const account = localStorage.getItem("account");
        localStorage.clear();
        localStorage.setItem("account", account);
        localStorage.setItem("layers", JSON.stringify(default_layers));
        localStorage.setItem("collection", JSON.stringify(default_collection))
        localStorage.setItem("creators", JSON.stringify(default_creators));
        window.location.reload();
    };


    toggleMenu = () => {
        this.setState((state) => ({ isMenuOpen: !state.isMenuOpen }));
    };

    textChange(event) {
        const key = event.target.name;
        const value = event.target.value;

        this.setState({ [key]: value });
        localStorage.setItem(key, value);

        console.log(`Updated ${key}: ${value}`);
    }



    startingCountChange(event) {
        const name = "starting_count";
        this.setState({
            [name]: event.target.value,
        });
        localStorage.setItem(name, event.target.value);
    }


    enterPressed(event) {
        const key = event.which || event.keyCode;
        if (key === 13) {
            const inputName = event.target.name;
            const inputValue = event.target.value;

            if (inputName.startsWith('rarityName_')) {
                const index = parseInt(inputName.split('_')[1], 10);
                this.handleRarityNameChange(index, inputValue);
            } else if (inputName.startsWith('rarityPercentage_')) {
                const index = parseInt(inputName.split('_')[1], 10);
                this.handleRarityPercentageChange(index, inputValue);
            }
        }
    }

    optionSelected(event) {
        if (event.target.value === "Ethereum") {
            localStorage.setItem(event.target.name, event.target.value);
            this.setState({ network: event.target.value });
        } else if (event.target.value === "Solana") {
            localStorage.setItem(event.target.name, event.target.value);
            this.setState({ network: event.target.value });
        }
    }

    getRaritiesFromLocalStorage = () => {
        let rarities = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (!isNaN(key)) {
                const rarityName = localStorage.getItem(key);
                const percentageKey = `${key}_percent`;
                const rarityPercentage = localStorage.getItem(percentageKey);
                rarities.push({ id: key, name: rarityName, percentage: rarityPercentage });
            }
        }
        rarities.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        return rarities;
    }

    componentDidMount() {
        let creators = localStorage.getItem("creators");
        if (creators) {
            try {
                if (typeof creators === "string") {
                    creators = JSON.parse(creators);
                }
                this.setState({ creatorList: creators });
            } catch (e) {
                console.error("Error parsing creators from localStorage:", e);
                this.setState({ creatorList: default_creators });
            }
        } else {
            this.setState({ creatorList: default_creators });
        }

        const layers = localStorage.getItem("layers");
        if (layers) {
            this.setState({ layers: JSON.parse(layers) });
        } else {
            this.setState({ layers: default_layers });
        }

        const collection = localStorage.getItem("collection");
        if (collection) {
            this.setState({ collection: JSON.parse(collection) });
        } else {
            this.setState({ collection: default_collection });
        }
    }


    componentDidUpdate(prevState) {
        if (prevState.layers !== this.state.layers) {
            localStorage.setItem("layers", JSON.stringify(this.state.layers));
        }
        if (prevState.creatorList !== this.state.creatorList) {
            localStorage.setItem("creators", JSON.stringify(this.state.creatorList));
        }
        if (prevState.collection !== this.state.collection) {
            localStorage.setItem("collection", JSON.stringify(this.state.collection))
        }
    }

    addCreator = (address, share) => {
        if (!address || !share) return;
        this.setState((state) => {
            const creatorList = [
                ...state.creatorList,
                { address, share },
            ];
            return { creatorList };
        });
    };

    deleteCreator = (index) => {
        this.setState((state) => {
            const creatorList = [...state.creatorList];
            creatorList.splice(index, 1);
            return { creatorList };
        });
    };

    addItem = () => {
        this.setState((state) => {
            const layers = [
                ...state.layers,
                { name: state.itemName, order: state.layers.length },
            ];
            return { layers, itemName: "" };
        });
    };

    deleteItem = (index) => {
        this.setState((state) => {
            const layers = [...state.layers];
            layers.splice(index, 1);

            layers.forEach((item, idx) => {
                item.order = idx;
            });

            return { layers };
        });
    };

    layerChange = (event) => {
        this.setState({
            itemName: event.target.value,
        });
    };

    collectionChange = (event) => {
        this.setState({
            itemName: event.target.value,
        })
    }

    addRarity = () => {
        let newId = 1;
        while (localStorage.getItem(newId.toString()) || localStorage.getItem(`${newId}_percent`)) {
            newId++;
        }

        localStorage.setItem(newId.toString(), '');
        localStorage.setItem(`${newId}_percent`, '');

        this.forceUpdate();
    };


    deleteRarity = (rarityId) => {
        localStorage.removeItem(rarityId.toString());
        localStorage.removeItem(`${rarityId}_percent`);

        this.forceUpdate();
    };

    updateRarity = (rarityId, key, value) => {
        if (key === 'name') {
            localStorage.setItem(rarityId.toString(), value);
        } else if (key === 'percentage') {
            localStorage.setItem(`${rarityId}_percent`, value);
        }

        this.forceUpdate();
    };

    updateCollection = (key, value) => {
        let collection = JSON.parse(localStorage.getItem("collection"));

        if (collection && Array.isArray(collection) && collection.length > 0) {
            collection[0][key] = value;
            localStorage.setItem("collection", JSON.stringify(collection));
            console.log(`Updated collection: ${key} = ${value}`);
        } else {
            console.error("Invalid collection in localStorage");
        }
    }



    render() {
        return (
            <div className="panel-box border border-slate-600 shadow-md bg-gray-700 shadow-slate-800 rounded-md text-sm">
                <main className="mt-2 mx-2">
                    <div className="panel-title border-b border-slate-600 mb-2 pb-2 rounded-md shadow-md">
                        <p className="text-md uppercase">Configurations</p>
                        <img className="w-4 h-4" src={configIcon} alt="Config" />
                    </div>
                    <div className="config-row text-left mx-1 text-sm py-1">
                        <div className="config-box">
                            <label className="config-label uppercase" for="name">
                                Title:
                            </label>
                            <input
                                className="config-input rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                type="text"
                                autoComplete="off"
                                placeholder={localStorage.getItem("title")}
                                onChange={this.textChange.bind(this)}
                                onKeyDown={this.enterPressed.bind(this)}
                                name="title"
                            ></input>
                        </div>
                    </div>

                    <div className="config-row text-left mx-1 text-sm py-1">
                        <div className="config-box2">
                            <label className="config-label uppercase" for="desc">
                                Description:
                            </label>
                            <div className="flex flex-row mt-1">
                                <textarea
                                    className="config-input2 rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                    rows="rows"
                                    type="text"
                                    autoComplete="off"
                                    placeholder={localStorage.getItem("desc")}
                                    onChange={this.textChange.bind(this)}
                                    onKeyDown={this.enterPressed.bind(this)}
                                    name="desc"
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="config-row text-left mx-1 text-sm py-1">
                        <div className="config-box">
                            <label className="config-label uppercase" for="name">
                                Network:
                            </label>
                            <select
                                className="config-input rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                value={this.state.network}
                                onChange={this.optionSelected.bind(this)}
                                type="text"
                                autocomplete="off"
                                placeholder={localStorage.getItem("network")}
                                name="network"
                            >
                                {networksList.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {this.state.network === "Solana" ? (
                        <div style={{ display: "block" }}>
                            <div className="config-row text-left mx-1 text-sm py-1">
                                <div className="config-box">
                                    <label className="config-label uppercase" for="name">
                                        External Url:
                                    </label>
                                    <input
                                        className="config-input rounded-md text-slate-50 bg-slate-600 border border-purple-400"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={localStorage.getItem("external_url")}
                                        onChange={this.textChange.bind(this)}
                                        onKeyDown={this.enterPressed.bind(this)}
                                        name="external_url"
                                    ></input>
                                </div>
                            </div>
                            <div className="config-row text-left mx-1 text-sm py-1">
                                <div className="config-box">
                                    <label className="config-label uppercase" for="name">
                                        Seller Fee:
                                    </label>
                                    <input
                                        className="config-input rounded-md text-slate-50 bg-slate-600 border border-purple-400"
                                        type="number"
                                        autoComplete="off"
                                        placeholder={localStorage.getItem(
                                            "seller_fee_basis_points"
                                        )}
                                        onChange={this.textChange.bind(this)}
                                        onKeyDown={this.enterPressed.bind(this)}
                                        name="seller_fee_basis_points"
                                    ></input>
                                </div>
                            </div>
                            <div className="config-row text-left mx-1 text-sm py-1">
                                <div className="config-box">
                                    <label className="config-label uppercase" for="name">
                                        Symbol:
                                    </label>
                                    <input
                                        className="config-input rounded-md text-slate-50 bg-slate-600 border border-purple-400"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={localStorage.getItem("symbol")}
                                        onChange={this.textChange.bind(this)}
                                        onKeyDown={this.enterPressed.bind(this)}
                                        name="symbol"
                                    ></input>
                                </div>
                            </div>

                            <div className="config-row text-left mx-1 text-sm my-2 pb-4 mb-4 bg-slate-700 shadow-slate-900 shadow-md rounded-md">
                                <p className="py-2 bg-gradient-to-r from-purple-800 to-purple-700 rounded-t-md flex justify-center items-center gap-2">
                                    Creators & Share
                                    <FaHandshake />
                                </p>
                                <div className="flex items-center justify-center gap-4 pt-3">
                                    <input
                                        className="config-input rounded-md text-slate-50 bg-slate-600 border border-purple-400"
                                        type="text"
                                        placeholder="address"
                                        onChange={(e) => this.setState({ addressInput: e.target.value })}
                                        value={this.state.addressInput}
                                    />
                                    <input
                                        className="w-20 rounded-md text-slate-50 bg-slate-600 border border-purple-400"
                                        type="number"
                                        placeholder="share"
                                        onChange={(e) => this.setState({ shareInput: e.target.value })}
                                        value={this.state.shareInput}
                                    />
                                    <button
                                        type="button"
                                        className="text-slate-50 hover:text-slate-200 ml-2"
                                        onClick={() => {
                                            this.addCreator(this.state.addressInput, this.state.shareInput);
                                            this.setState({ addressInput: "", shareInput: "" });
                                        }}
                                    >
                                        <FaPlusSquare />
                                    </button>
                                </div>
                                <div className="pt-2 mx-2">
                                    {this.state.creatorList.map((item, index) => (
                                        <ul
                                            key={index}
                                            className="border-b shadow-sm border-slate-500 px-2"
                                        >
                                            <li className="py-1">
                                                <button
                                                    type="button"
                                                    onClick={() => this.deleteCreator(index)}
                                                    className="text-red-400 active:text-red-500 mr-2 my-2"
                                                >
                                                    <FaTrash />
                                                </button>
                                                Address {index + 1}: {item.address}
                                            </li>
                                            <li className="py-1">Share: {item.share}</li>
                                        </ul>
                                    ))}
                                </div>
                            </div>

                            <div className="config-row text-left mx-1 text-sm my-2 pb-4 mb-4 bg-slate-700 shadow-slate-900 shadow-md rounded-md">
                                <p className="py-2 bg-gradient-to-r from-purple-800 to-purple-700 rounded-t-md flex justify-center items-center gap-2">
                                    Collection
                                    <FaSignature />
                                </p>

                                <div className="config-row text-left mx-4 text-sm py-1 mt-2">
                                    <div className="config-box">
                                        <label className="config-label uppercase" for="collection_name">
                                            C. Name:
                                        </label>
                                        <input
                                            className="config-input rounded-md text-slate-50 bg-slate-600 border border-purple-400"
                                            type="text"
                                            autoComplete="off"
                                            placeholder={JSON.parse(localStorage.getItem("collection"))[0].name}
                                            onChange={(e) => this.updateCollection('name', e.target.value)}
                                            name="name"
                                        ></input>
                                    </div>
                                </div>

                                <div className="config-row text-left mx-4 text-sm py-1">
                                    <div className="config-box">
                                        <label className="config-label uppercase" for="collection_family">
                                            C. Family:
                                        </label>
                                        <input
                                            className="config-input rounded-md text-slate-50 bg-slate-600 border border-purple-400"
                                            type="text"
                                            autoComplete="off"
                                            placeholder={JSON.parse(localStorage.getItem("collection"))[0].family}
                                            onChange={(e) => this.updateCollection('family', e.target.value)}
                                            name="family"
                                        ></input>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ) : (
                        <div style={{ display: "none" }}></div>
                    )}

                    <div className="config-row text-left mx-1 text-sm py-1">
                        <div className="config-box">
                            <label className="config-label uppercase" htmlFor="name">
                                Starting Count:
                            </label>
                            <select
                                className="config-input rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                value={localStorage.getItem("starting_count")}
                                onChange={this.startingCountChange.bind(this)}
                                name="starting_count"
                            >
                                <option value="0">0</option>
                                <option value="1">1</option>
                            </select>
                        </div>
                    </div>


                    <div className="config-row text-left mx-1 text-sm py-1">
                        <div className="config-box">
                            <label className="config-label uppercase" for="name">
                                baseUri:
                            </label>
                            <input
                                className="config-input rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                type="text"
                                autoComplete="off"
                                placeholder={localStorage.getItem("baseUri")}
                                onChange={this.textChange.bind(this)}
                                onKeyDown={this.enterPressed.bind(this)}
                                name="baseUri"
                            ></input>
                        </div>
                    </div>

                    <div className="config-row text-left mx-1 text-sm py-1">
                        <div className="config-box">
                            <label className="config-label uppercase" for="name">
                                Quantity:
                            </label>
                            <input
                                className="config-input rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                type="number"
                                autoComplete="off"
                                placeholder={localStorage.getItem("quantity")}
                                onChange={this.textChange.bind(this)}
                                onKeyDown={this.enterPressed.bind(this)}
                                name="quantity"
                            ></input>
                        </div>
                    </div>

                    <div className="config-row text-left mx-1 text-sm py-1">
                        <div className="config-box3 mx-auto">
                            <label
                                className="config-label uppercase whitespace-nowrap"
                                for="name"
                            >
                                Resolution:
                            </label>
                            <div className="flex flex-col items-center whitespace-nowrap">
                                <p className="text-sm italic mb-1 text-gray-400">width (px)</p>
                                <input
                                    className="mb-6 w-20 rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                    type="number"
                                    autoComplete="off"
                                    placeholder={localStorage.getItem("width")}
                                    onChange={this.textChange.bind(this)}
                                    onKeyDown={this.enterPressed.bind(this)}
                                    name="width"
                                ></input>
                            </div>

                            <div className="flex flex-col items-center whitespace-nowrap ml-4">
                                <p className="text-sm italic mb-1 text-gray-400">height (px)</p>
                                <input
                                    className="mb-6 w-20 rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                    type="number"
                                    autoComplete="off"
                                    placeholder={localStorage.getItem("height")}
                                    onChange={this.textChange.bind(this)}
                                    onKeyDown={this.enterPressed.bind(this)}
                                    name="height"
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="bg-slate-700 shadow-slate-900 shadow-md rounded-md">
                        <p className="py-2 bg-gradient-to-r from-gray-800 to-gray-700 rounded-t-md flex justify-center items-center gap-2">
                            Rarity Names
                            <FaHandSparkles />
                        </p>
                        <div className="config-row text-left mx-1 text-sm p-2">
                            {this.getRaritiesFromLocalStorage().map((rarity, index) => (
                                <div key={index} className="config-box my-2">
                                    <label className="config-label uppercase" htmlFor={`rarity_name_${index}`}>
                                        {rarity.id}. {rarity.name}
                                    </label>
                                    <input
                                        className="config-input rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                        type="text"
                                        autoComplete="off"
                                        placeholder={rarity.name}
                                        onChange={(e) => this.updateRarity(rarity.id, 'name', e.target.value)}
                                    ></input>
                                    <input
                                        className="config-input-percent rounded-md text-slate-50 bg-slate-600 border border-slate-400 ml-2"
                                        type="number"
                                        autoComplete="off"
                                        placeholder={rarity.percentage}
                                        onChange={(e) => this.updateRarity(rarity.id, 'percentage', e.target.value)}
                                    ></input>
                                    <button
                                        type="button"
                                        className="text-red-400 active:text-red-500 ml-2"
                                        onClick={() => this.deleteRarity(rarity.id)}
                                    >
                                        <FaTrash />
                                    </button>
                                </div>
                            ))}
                            <button
                                type="button"
                                className="text-slate-50 hover:text-slate-200"
                                onClick={this.addRarity}
                            >
                                <FaPlusSquare />
                            </button>
                        </div>
                    </div>
                    <div className="bg-slate-700 mt-6 shadow-slate-900 shadow-md rounded-lg">
                        <p className="py-2 bg-gradient-to-r from-gray-800 to-gray-700 rounded-t-md flex justify-center items-center gap-2">
                            Match Layer Names
                            <img className="w-4 h-4" src={layerIcon} alt="Layers" />
                        </p>
                        <div className="config-row text-left mx-1 text-sm py-1">
                            <div className="config-box p-2">
                                <label className="config-label uppercase text-md" for="name">
                                    Layer name:
                                </label>
                                <input
                                    className="config-input rounded-md text-slate-50 bg-slate-600 border border-slate-400"
                                    type="text"
                                    value={this.state.itemName}
                                    onChange={this.layerChange}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            this.addItem();
                                        }
                                    }}
                                />

                                <button
                                    type="button"
                                    onClick={this.addItem}
                                    className="text-slate-50 hover:text-slate-200 ml-2"
                                >
                                    <FaPlusSquare />
                                </button>
                            </div>
                            <div className="list-container p-2">
                                <ul>
                                    {this.state.layers.map((item, index) => (
                                        <li key={index} className="flex items-center">
                                            <button
                                                type="button"
                                                onClick={() => this.deleteItem(index)}
                                                className="text-red-400 active:text-red-500 mr-2 my-2"
                                            >
                                                <FaTrash />
                                            </button>
                                            <span className="border-b border-dashed border-slate-400">
                                                {item.order}. {item.name}
                                            </span>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="my-4 flex justify-center">
                        <button
                            onClick={this.handleReset}
                            className="bg-red-500 hover:bg-red-400 border-red-900 border-2 shadow-slate-900 shadow-md text-red-900 rounded-md px-3 py-0.5 text-lg font-bold m-2"
                        >
                            Reset
                        </button>
                        <button
                            onClick={this.clickSetup}
                            className="bg-gray-500 hover:bg-gray-400 border-gray-900 border-2 shadow-slate-900 shadow-md text-gray-900 rounded-md px-3 py-0.5 text-lg font-bold m-2"
                            disabled={this.state.setupIsValid}
                        >
                            <FaDownload />
                        </button>
                    </div>
                </main>
            </div>
        );
    }
}

export default Config;
