import React from "react";
import ReleaseNotes from "../release-notes.json";

export default function AppendList(): JSX.Element {
    const items: string[] = ReleaseNotes.notes;

    return (
        <ul>
            {items.map((item: string, index: number) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
}
