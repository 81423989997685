import React from "react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

interface NoNetworkProps {
    error: {
        message: string;
    };
}

const NoNetwork: React.FC<NoNetworkProps> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="bg-slate-300 flex items-center w-screen h-screen">
            <div className="mx-auto text-slate-800 px-2 flex-col w-2/3">
                <h1 className="text-3xl my-2">
                    Either you don't have Metamask installed or you are connected to the
                    wrong network. Please install{" "}
                    <span className="font-bold">Metamask</span> or connect to the{" "}
                    <span className="font-bold">Fantom Mainnet</span>
                </h1>
                <div className="mx-auto w-2/3 my-8">
                    <p className="text-lg my-2 py-1 px-2 bg-slate-200 rounded-xl shadow-md text-red-700">
                        <span className="font-bold text-red-700">Error:</span>{" "}
                        {props.error.message}
                    </p>
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={() => navigate('/verify')}
                        className="flex items-center my-2 text-3xl gap-2 mt-2 text-slate-800 hover:text-slate-600"
                    >
                        <FaArrowAltCircleLeft />
                        <span className="text-xl">Go back</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NoNetwork;
