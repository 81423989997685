import Popup from "reactjs-popup";
import { version } from '../pages/Program';
import AppendList from "../components/AppendList";
import "../css/Version.css";

export default function Version() {
    return (
        <Popup
            trigger={
                <button className="tab-box ml-4 bg-slate-800 hover:bg-slate-900 text-slate-50 px-4 rounded-lg border border-slate-500 shadow-md">
                    {" "}
                    v.{version}
                </button>
            }
        >
            <div className="version-popup bg-gradient-to-t from-slate-300 to-slate-400 border-b border-x border-slate-600 shadow-lg shadow-slate-700 rounded-md">
                <div className="version-box text-slate-900 mx-2 my-2 rounded-lg shadow-lg bg-slate-400 text-md">
                    <h1 className="version-title text-slate-50 bg-gradient-to-r from-slate-800 to-slate-600 uppercase font-bold rounded-t-lg pl-1">
                        Update v.{version}
                    </h1>
                    <p className="bg-slate-300 border border-slate-400 shadow-md rounded-tr-xl rounded-b-xl w-24 px-1 mt-2 font-bold">
                        Updated:
                    </p>
                    <div className="version-content italic">
                        <AppendList />
                    </div>
                </div>
            </div>
        </Popup>
    );
}
