import React from "react";
import { FaDownload } from "react-icons/fa";
const engineIcon = "/images/engine.svg";

export default function Engine() {
    const latest_version: string = "v.1.3";

    const downloadRepo = (): void => {
        const repoURL: string = "https://github.com/brandonhann/mintygen-engine/archive/refs/heads/main.zip";
        window.open(repoURL);
    };

    return (
        <div className="panel-box border border-slate-600 shadow-md shadow-slate-800 rounded-md text-sm">
            <div className="mt-2 mx-2">
                <div className="panel-title border-b border-slate-600 mb-2 pb-2 rounded-md shadow-md flex items-center text-slate-50">
                    <h1 className="text-md uppercase">Engine Download </h1>
                    <img className="w-4 h-4" src={engineIcon} alt="Engine Logo" />
                </div>
                <div className="p-1 text-md">
                    <div className="flex items-center gap-2 justify-center">
                        <h2 className="italic">Latest version: {latest_version}</h2>
                        <button
                            onClick={downloadRepo}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                        >
                            <FaDownload />
                        </button>
                    </div>
                    <div className="flex items-center gap-2 justify-center">
                        <a className="text-blue-300 hover:underline" href="https://github.com/brandonhann/mintygen-engine" target="_blank" rel="noreferrer">
                            Alternative Github Link
                        </a>
                    </div>
                </div>
                <div className="p-1 text-md">
                    <div className="flex items-center gap-2 justify-center mt-3 pt-4 border-t border-slate-600 uppercase">
                        <h2>Requirements</h2>
                    </div>
                    <div className="flex items-center gap-2 justify-center mt-2 text-blue-300 hover:underline">
                        <a href="https://nodejs.org/" target="_blank" rel="noreferrer">NodeJS</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
