import React, { useState } from "react";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

type Web3Props = {};

const Web3: React.FC<Web3Props> = () => {
    let hash: string | undefined;
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [defaultAccount, setDefaultAccount] = useState<string | null>(null);
    const [userBalance, setUserBalance] = useState<string | null>(null);
    const [connectBtnTxt, setConnectBtnTxt] = useState("Verify");
    const [connectStatusOffline, setConnectStatusOffline] = useState("● ");
    const [connectStatusOnline, setConnectStatusOnline] = useState<string | undefined>();
    const [account, setAccount] = useLocalStorage("account", hash);

    const ConnectWalletHandler = () => {
        if (window.ethereum) {
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((result: string[]) => {
                    AccountChangedHandler(result[0]);
                    setConnectStatusOnline("● ");
                    setConnectStatusOffline("");
                    setConnectBtnTxt("Loading...");
                    navigate("/app");
                });
        } else {
            setErrorMessage("Install MetaMask");
        }
    };


    const AccountChangedHandler = (newHash: string) => {
        setDefaultAccount(newHash);
        GetUserBalance(newHash.toString());
        hash = newHash;
        setAccount(hash);
    };

    const GetUserBalance = (address: string) => {
        if (window.ethereum) {
            window.ethereum
                .request({ method: "eth_getBalance", params: [address, "latest"] })
                .then((balance: string) => {
                    setUserBalance(ethers.utils.formatEther(balance));
                });
        } else {
            setErrorMessage("Install MetaMask");
        }
    };

    const ChainChangedHandler = () => {
        window.location.reload();
    };

    if (window.ethereum) {
        window.ethereum.on("accountsChanged", AccountChangedHandler);
        window.ethereum.on("chainChanged", ChainChangedHandler);
    }

    return (
        <div className="walletCard flex gap-4">
            <button
                className="custom-button bg-blue-500 border-b-2 border-blue-700 active:bg-blue-700 shadow-md text-blue-50 px-5 py-2 rounded-md text-2xl"
                onClick={ConnectWalletHandler}
            >
                <p>
                    <span className="status text-red-400">{connectStatusOffline}</span>
                    <span className="status text-green-400">{connectStatusOnline}</span>
                    {connectBtnTxt}
                </p>
            </button>
        </div>
    );
};

export default Web3;
