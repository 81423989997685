import React from "react";
import { FaCircle } from "react-icons/fa";
import Popup from "reactjs-popup";
import "../css/Connected.css";

export default function Connected(): JSX.Element {
    const account = localStorage.getItem("account");
    return (
        <div>
            <Popup
                trigger={
                    <div className="connected-box text-sm text-green-400 px-4 rounded-lg border border-green-200 shadow-sm shadow-green-300">
                        <div className="connected-txt">
                            <span>
                                <FaCircle />
                            </span>
                        </div>
                    </div>
                }
            >
                <div className="connected-popup bg-slate-400 py-1 px-2 rounded-lg text-slate-900 shadow-lg shadow-slate-700 font-bold font-lg flex flex-row gap-2 text-center border border-slate-600">
                    <p>Connected: </p>
                    <div className="tooltip-trigger">
                        <p>{account!.substring(1, 6) + "..." + account!.substring(39, 43)}</p>
                    </div>
                    <span className="tooltip text-slate-300 rounded-lg px-4 bg-slate-900">
                        {JSON.parse(account!)}
                    </span>
                </div>
            </Popup>
        </div>
    );
}