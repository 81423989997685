import Home from "./pages/Home";
import Program from "./pages/Program";
import Verify from "./pages/Verify";
import Buy from "./pages/Buy";
import NoKey from "./components/NoKey";
import NoNetwork from "./components/NoNetwork";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Contract, providers } from "ethers";
import { useEffect, useState } from "react";
import CONTRACT_ABI from "./eth/abi.json";

// global variables
export const CONTRACT_ADDRESS = "0x93056c5135b17dFA45099dbc448d9bd458d4840d";
export var DEVELOPER_MODE = false;

export const DISCORD_URL = 'https://discord.com/invite/7Y97wzrVMe';
export const INSTAGRAM_URL = 'https://www.instagram.com/minty.gen/';
export const YOUTUBE_URL = 'https://youtube.com/@brandon_hann';
export const X_URL = 'https://twitter.com/MintyGen';
export const REDDIT_URL = 'https://www.reddit.com/r/mintygen';
export const GITHUB_URL = 'https://github.com/brandonhann';

async function checkNFT(): Promise<JSX.Element> {
  if (DEVELOPER_MODE) {
    return <Program />;
  }

  if (!window.ethereum) {
    return <NoNetwork error={{ message: "MetaMask is not available" }} />;
  }

  try {
    await window.ethereum.request({ method: "eth_requestAccounts" });

    const provider = new providers.Web3Provider(window.ethereum);
    const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);
    const balance = await contract.balanceOf(window.ethereum.selectedAddress);

    if (balance.gt(0)) {
      return <Program />;
    } else {
      document.title = "Access Denied";
      return <NoKey />;
    }
  } catch (error) {
    if (error instanceof Error) {
      return <NoNetwork error={{ message: error.message }} />;
    } else {
      return <NoNetwork error={{ message: 'An unknown error occurred.' }} />;
    }
  }

}

function AppRoutes(): JSX.Element {
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      if (location.pathname === "/verify" || location.pathname === "/app") {
        const result = await checkNFT();
        setComponent(result);
      }
    }
    fetchData();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/app" element={component} />
      <Route path="/buy" element={<Buy />} />
    </Routes>
  );
}

export default function App(): JSX.Element {
  return (
    <Router>
      <div className="App">
        <AppRoutes />
      </div>
    </Router>
  );
}