import React from "react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const account = localStorage.getItem("account");
const key = "/images/spinning-key.gif";

const NoKey: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="bg-slate-300 flex items-center w-screen h-screen">
            <div className="mx-auto text-slate-800 px-2 flex-col">
                <h1 className="text-3xl my-2">
                    You do not have the license key
                    <img className="inline -mx-4" src={key} width="75px" height="75px" alt="Spinning key" />
                    required to access this page.
                </h1>
                <div className="flex justify-center items-center">
                    <p className="my-2 py-1 px-2 bg-slate-200 rounded-xl shadow-md">
                        Connected with: <strong>{account!.substring(1, 43)}</strong>
                    </p>
                </div>
                <p className="text-lg my-2">
                    If you do not own this software -{" "}
                    <a className="underline" href={`${process.env.PUBLIC_URL}/buy`} rel="noreferrer" target="_blank">
                        <strong>Buy Here</strong>
                    </a>
                </p>
                <div className="flex justify-center">
                    <button
                        onClick={() => navigate('/verify')}
                        className="flex items-center my-2 text-3xl gap-2 mt-2 text-slate-800 hover:text-slate-600"
                    >
                        <FaArrowAltCircleLeft />
                        <span className="text-xl">Go back</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NoKey;
