import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Web3 from "../components/Web3";

const logo = "/images/ink-logo.gif";
const key = "/images/spinning-key.gif";

export default function Verify(): JSX.Element {
    const navigate = useNavigate();

    useEffect(() => {
        const prevTitle = document.title;
        document.title = "Verify License Key";
        return () => {
            document.title = prevTitle;
        }
    }, []);

    return (
        <div className="bg min-h-screen flex items-center justify-center">
            <header className="flex flex-col items-center">
                <div className="panel p-4 md:p-6 lg:p-10">
                    <h1 className="m-auto title-txt text-4xl md:text-6xl lg:text-8xl text-gray-800">MintyGen</h1>
                </div>
                <img src={logo} className="w-32 md:w-48 lg:w-64 h-auto" alt="Logo" />
                <Web3 />
                <div className="info-box mt-4 w-full text-center px-4 md:px-6 lg:px-10">
                    <p className="text-lg md:text-xl lg:text-2xl font-bold">
                        Please connect your wallet and verify your Minty Key
                        <img
                            className="inline -mx-4"
                            src={key}
                            width="50px"
                            height="50px"
                            alt="Key"
                        />
                        {" "}to unlock the software
                    </p>
                    <p className="text-sm md:text-base lg:text-lg my-4">
                        If you do not own this software -{" "}
                        <a
                            className="underline"
                            href={`${process.env.PUBLIC_URL}/buy`}
                            rel="noopener noreferrer"
                        >
                            <span className="font-bold">Buy Here</span>
                        </a>
                    </p>
                </div>
                <button
                    className="custom-button bg-red-500 border-b-2 border-red-700 text-red-50 px-5 py-2 rounded-md hover:bg-red-600 text-lg"
                    onClick={() => navigate('/')}
                >
                    Go Back
                </button>
            </header>
        </div>
    );
}