import React from "react";
import HelpView from "./HelpView";
import { FaLevelDownAlt, FaExternalLinkAlt } from "react-icons/fa";
import "../css/MainView.css";

export default function MainView(): JSX.Element {
    return (
        <div>
            <main className="lg:flex content-start text-slate-50 px-4 py-2 h-full mx-2 my-2">
                <section className="text-left flex-col w-full lg:w-1/2 pr-4 space-y-2">
                    <h1 className="text-2xl border-b-2 border-slate-400">
                        Thank you for purchasing MintyGen ツ
                    </h1>
                    <p>
                        Welcome to MintyGen, the first ever NFT generative software. This
                        app is still in the early stages of development. Please report any
                        bugs or issues to our discord so we can improve the final product
                        for our customers. Right below, you can find an informative tutorial
                        video giving an in-depth insight into the usage of this software.
                        Please refer to this video, as it is a beginners' guide as well.
                    </p>
                    <div className="flex gap-2">
                        <p className="text-lg">MintyGen Tutorial:</p>
                        <span className="text-slate-400 mt-2">
                            <FaLevelDownAlt />
                        </span>
                    </div>
                    <div className="pb-2 video-responsive">
                        <iframe
                            title="MintyGen Tutorial"
                            width="427"
                            height="240"
                            src="https://www.youtube.com/embed/none"
                            className="rounded-lg shadow-lg shadow-slate-900"
                        ></iframe>
                    </div>
                </section>
                <section className="text-left flex-col w-full lg:w-1/2 pl-4 space-y-2">
                    <h2 className="text-2xl border-b-2 border-slate-400">FAQ</h2>
                    <p>
                        Refer to the content below for{" "}
                        <span className="text-yellow-300">answers</span> to frequently asked
                        <span className="text-red-300"> questions</span>. Please don't
                        hesitate to join our discord community!
                    </p>
                    <div className="space-y-4">
                        <ul className="border-2 border-slate-500 bg-slate-700 p-2 shadow-md shadow-slate-900 rounded-md">
                            <li>
                                <span className="text-red-300 font-semibold">Q.</span> I need
                                assistance, who can I ask?
                            </li>
                            <li className="pl-4">
                                <span className="text-yellow-300 font-semibold">A.</span> Create
                                a ticket within our{" "}
                                <a
                                    className="text-blue-300 hover:underline inline-flex align-items-center items-center"
                                    href="https://discord.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>discord</span>
                                    <span className="text-xs mx-1">
                                        <FaExternalLinkAlt />
                                    </span>
                                </a>
                                and we can assist with any questions or concerns.
                            </li>
                        </ul>
                        <ul className="border-2 border-slate-500 bg-slate-700 p-2 shadow-md shadow-slate-900 rounded-md">
                            <li>
                                <span className="text-red-300">Q.</span> Can I access this
                                software on mobile?
                            </li>
                            <li className="pl-4">
                                <span className="text-yellow-300 font-semibold">A.</span> It's
                                difficult for mobile devices to render a large quantity of
                                images in one process; therefore, the MintyGen Engine
                                unfortunately cannot be compatible with iOS and Android.
                                However, the web compiler is accessible on mobile devices.
                            </li>
                        </ul>
                        <ul className="border-2 border-slate-500 bg-slate-700 p-2 shadow-md shadow-slate-900 rounded-md">
                            <li>
                                <span className="text-red-300 font-semibold">Q.</span> I
                                encountered a bug, what do I do?
                            </li>
                            <li className="pl-4">
                                <span className="text-yellow-300 font-semibold">A.</span> We
                                would appreciate it a lot if you could report any bugs in the
                                "report-bugs" channel within our discord.
                            </li>
                        </ul>
                    </div>
                </section>
            </main>
            <HelpView />
        </div>
    );
}
