import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaShoppingCart } from "react-icons/fa";
import { faTwitter, faDiscord, faInstagram, faYoutube, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLock, faKey, faDice, faTrophy } from '@fortawesome/free-solid-svg-icons';
import "../css/Home.css";
import { DISCORD_URL, INSTAGRAM_URL, X_URL, REDDIT_URL, GITHUB_URL } from "../App";

const sampleImage = '/images/sample.jpg';
const sampleImage2 = '/images/sample2.jpg';
const configImage = "/images/config.svg";
const engineImage = "/images/engine.svg";
const spinningKey = "/images/spinning-key.gif"
const iconImage = "/images/ink.svg";
// const maticIcon = "/images/matic.svg"
const ftmIcon = "/images/ftm.svg"

const Sponsor1Image = "/images/aws-brand.png";
const Sponsor2Image = "/images/covalent-brand.png";
const Sponsor3Image = "/images/fantom-brand.png";
const HackathonImage = "/images/hackathon.jpg";

export default function Home() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [visible, setVisible] = useState(true);
    const [lastDisappearPosition, setLastDisappearPosition] = useState(0); const scrollThreshold = 400;

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.pageYOffset;

            if (currentScrollPosition < scrollThreshold) {
                setVisible(true);
                setLastDisappearPosition(0);
            } else {
                if (scrollPosition < currentScrollPosition || currentScrollPosition === 0) {
                    if (visible) {
                        setLastDisappearPosition(scrollPosition);
                    }
                    setVisible(false);
                } else {
                    setVisible(scrollPosition <= lastDisappearPosition);
                }
            }

            setScrollPosition(currentScrollPosition);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollPosition, visible, lastDisappearPosition]);

    return (
        <div className="bg mx-auto m-0 min-h-screen flex flex-col">
            <header
                className={`fixed top-0 w-full bg-gray-50 py-2 shadow-sm border-b border-gray-300 z-50 ${visible ? 'show-navbar' : 'hide-navbar'
                    }`}
            >
                <nav className="md:w-3/4 mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="flex justify-center md:justify-start items-center">
                            <div className='pr-0 md:pr-4 md:border-r-2 border-gray-300'>
                                <a href={`${process.env.PUBLIC_URL}`} className="flex flex-row items-center gap-1 font-bold text-xl text-gray-800"><span>MintyGen</span><img className='hidden md:block w-6 h-6' src={iconImage} alt="Ink Icon" /></a>
                            </div>
                            <ul className="flex space-x-4 ml-4">
                                <li className='social-button'><a href={DISCORD_URL} rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faDiscord} className="text-blue-600 text-2xl" /></a></li>
                                <li className='social-button'><a href={X_URL} rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faTwitter} className="text-blue-400 text-2xl" /></a></li>
                                <li className='social-button'><a href={INSTAGRAM_URL} rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faInstagram} className="text-pink-500 text-2xl" /></a></li>
                            </ul>
                        </div>
                        <div className="flex justify-center md:justify-end items-center mt-2 md:mt-0 font-bold">
                            <Link to="/buy">
                                <button className="custom-button bg-blue-500 active:bg-blue-700 border-b-2 border-blue-700 shadow-md text-white px-5 py-2 rounded-md mr-4"><span className='drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] flex items-center gap-1'>Buy <FaShoppingCart /></span></button>
                            </Link>
                            <Link to="/verify">
                                <button className="custom-button bg-blue-500 border-b-2 border-blue-700 active:bg-blue-700 shadow-md text-white px-5 py-2 rounded-md "><span className='drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]'>Login</span></button>
                            </Link>
                        </div>
                    </div>
                </nav>
            </header>

            <main className="home-main md:w-3/4 mx-auto pt-0 md:pt-24 flex-grow">

                {/* Banner */}
                <motion.section
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.1 }}
                >
                    <div className="banner relative h-72 md:h-80 bg-cover bg-center md:rounded-md">
                        <div className="relative">
                            <div className="text-center pt-8">
                                <h1 className="title-txt text-4xl md:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-200 via-blue-200 to-blue-400 uppercase">MintyGen</h1>
                                <p className="text-gray-300 mt-2 text-lg md:text-xl">The ultimate NFT generator</p>
                                <p className='text-gray-300 text-sm'>Mint your license key</p>
                            </div>
                            <img src={spinningKey} className="mx-auto w-44 h-44" alt="A key spinning" />
                        </div>
                    </div>
                    <div className="text-center px-2 md:px-0 mt-8">
                        <p className="text-gray-600">
                            MintyGen is an innovative NFT generator app that allows you to easily create NFTs. With our user-friendly web compiler and powerful engine, you can customize various aspects of your NFTs with just a few clicks. We offer a purchasable license key on the Fantom network for a quick and secure experience.
                        </p>
                    </div>
                </motion.section>


                {/* Explanation */}
                <motion.section
                    className="mt-8 px-2 md:px-0 pt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.1 }}
                >
                    <h2 className="text-2xl py-1 font-bold text-gray-800 text-center w-full rounded-md m-auto">2 Parts to MintyGen <FontAwesomeIcon icon={faDice} className="text-2xl" /></h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 mt-8">
                        <div style={{ backgroundImage: `url(${sampleImage})`, backgroundSize: 'cover' }} className="bg-image-div text-center bg-gray-50 p-2 md:p-8 rounded-md border border-gray-300 shadow-sm transition-all duration-300 transform md:hover:scale-110 md:hover:shadow-2xl">
                            <div className="flex flex-row items-center justify-center space-x-2">
                                <h3 className="text-xl font-bold text-gray-50 cursor-default">MintyGen Compiler</h3>
                                <img className='box-icon' src={configImage} alt="Compiler Icon" />
                            </div>
                            <p className="text-gray-300 cursor-default">
                                Use our intuitive web compiler to create configuration files in JSON format. Easily customize various aspects of your NFTs with just a few clicks.
                            </p>
                        </div>
                        <div style={{ backgroundImage: `url(${sampleImage2})`, backgroundSize: 'cover' }} className="bg-image-div text-center bg-gray-50 p-2 md:p-8 rounded-md border border-gray-300 shadow-sm transition-all duration-300 transform md:hover:scale-110 md:hover:shadow-2xl">
                            <div className="flex flex-row items-center justify-center space-x-2">
                                <h3 className="text-xl font-bold text-gray-50 cursor-default">MintyGen Engine</h3>
                                <img className='box-icon' src={engineImage} alt="Engine Icon" />
                            </div>
                            <p className="text-gray-300 cursor-default">
                                Download the MintyGen engine onto your computer, and simply import the configuration file to generate your NFTs. Watch your unique creations come to life with the power of our state-of-the-art engine.
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center mt-12">
                        <Link to="/buy">
                            <button className="custom-button flex items-center gap-1 text-xl bg-slate-300 border-b-2 border-blue-500 shadow-md text-blue-500 font-bold p-4 rounded-md"><span>Get started for 85</span> <img className='w-5 h-5' src={ftmIcon} alt="Fantom logo" /></button>
                        </Link>
                    </div>


                </motion.section>

                {/* Information */}
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    className="mt-12 px-2 md:px-0 pt-4 text-center md:text-left"
                >
                    <h2 className="text-2xl py-1 font-bold text-gray-800 text-center w-full rounded-md m-auto">MintyGen's Key Features <FontAwesomeIcon icon={faKey} className="text-2xl" /></h2>
                    <ul className="mt-8 text-gray-600 list-none md:list-disc">
                        <li>
                            The MintyGen compiler provides advanced customization options to help you fine-tune your NFTs. From adjusting colors and patterns to setting rarity levels, our platform gives you full control over your NFT creation process.
                        </li>
                        <li className='mt-4'>
                            Additionally, our engine software offers a wide range of features designed to help you create the perfect NFTs for your project. With an extensive library of pre-made assets and the ability to upload your own, you can truly make your NFTs one-of-a-kind.
                        </li>
                        <li className='mt-4'>
                            MintyGen's unique feature allows software creators to sell their products as an NFT license key, giving buyers a verified proof of ownership. Users can access the software using the key and prove ownership through the blockchain. This provides a secure and streamlined way to monetize and purchase legitimate software.
                        </li>
                    </ul>
                </motion.section>

                {/* Hackathon Winner */}
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1 }}
                    className="mt-12 px-2 md:px-0 pt-4 text-center md:text-left"
                >
                    <div className="max-w-4xl mx-auto">
                        <h2 className="text-center text-2xl py-1 mb-0 md:mb-8 font-bold text-gray-800">🎉 Winner of the Fantom 2023 Q2 Hackathon <FontAwesomeIcon icon={faTrophy} className="text-2xl" />🎉</h2>
                        <div className="grid md:grid-cols-2 gap-2 md:gap-6 items-center justify-items-center md:justify-items-start">
                            <img className='hidden md:block rounded-md shadow-md w-48 h-26 md:w-96 md:h-52' src={HackathonImage} alt="Hackathon Icon" />
                            <div>
                                <p className="mt-4 text-gray-600">Our journey began as a project submission to the Fantom Q2 2023 Hackathon, where MintyGen won an honorable mention. The event was a great opportunity for us to showcase our capabilities and connect with talented developers. Read more about our experience below.</p>
                                <div className="mt-4= flex flex-col md:flex-row gap-2 md:gap-6 mt-2 md:mt-0 items-center">
                                    <a className="text-blue-500 hover:underline" href="https://devpost.com/software/mintygen" target="_blank" rel="noopener noreferrer">Devpost</a>
                                    <a className="text-blue-500 hover:underline" href="https://fantom.foundation/blog/winners-announced-aws-fantom-hackathon-q2-2023/" target="_blank" rel="noopener noreferrer">Blog Announcement</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.section>

                {/* Sponsors */}
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1.25 }}
                    className="mt-12 px-2 md:px-0 pt-4 text-center"
                >
                    <div className="flex flex-wrap justify-center items-center gap-12 md:gap-24">
                        <img className='w-24 h-14 filter grayscale md:hover:grayscale-0 opacity-75 md:hover:opacity-100 transition duration-300' src={Sponsor1Image} alt="Sponsor 1" />
                        <img className='w-48 h-12 filter grayscale md:hover:grayscale-0 opacity-75 md:hover:opacity-100 transition duration-300' src={Sponsor2Image} alt="Sponsor 2" />
                        <img className='w-48 h-12 filter grayscale md:hover:grayscale-0 opacity-75 md:hover:opacity-100 transition duration-300' src={Sponsor3Image} alt="Sponsor 3" />
                    </div>
                </motion.section>

                {/* Tutorial video and text container */}
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.75 }}
                    className="mt-12 px-2 md:px-0 pt-4 "
                >
                    <h2 className="text-2xl font-bold text-gray-800 text-center w-full rounded-md m-auto">Watch Our Tutorial <FontAwesomeIcon icon={faYoutube} className="text-2xl" /></h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8 items-center">
                        <div className="mx-auto" style={{ maxWidth: '640px', width: '100%' }}>
                            <div className="rounded-md overflow-hidden" style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                                <iframe
                                    title="MintyGen Tutorial"
                                    src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                />
                            </div>
                        </div>

                        <div className="bg-opacity-10 bg-gray-500 p-4 md:p-8 rounded-md border border-gray-300 shadow-sm text-center md:text-left">
                            <h3 className="text-xl font-bold text-gray-800">Need Help?</h3>
                            <p className="text-gray-600 mt-4">
                                To gain insight into how to use the MintyGen compiler and software, we highly recommend watching the MintyGen video tutorial. This will guide you through the entire process and help you understand how to use the software efficiently.
                            </p>
                            <p className="text-gray-600 mt-4">
                                Join our Discord community to get help, report bugs, and connect with other MintyGen users. It's a great way to interact with like-minded individuals and contribute to the development of the software.
                            </p>
                            <a href={DISCORD_URL} target='_blank' rel="noreferrer" className="custom-button bg-blue-600 hover:bg-blue-800 text-white font-bold mt-4 py-2 px-4 rounded inline-flex items-center gap-1">
                                <span>Join Discord</span>
                                <FontAwesomeIcon icon={faDiscord} className="text-xl" />
                            </a>

                        </div>
                    </div>
                </motion.section>

                {/* Open Source */}
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1 }}
                    className="mt-12 px-2 md:px-0 pt-4 text-center md:text-left"
                >
                    <h2 className="text-2xl py-1 font-bold text-gray-800 text-center w-full rounded-md m-auto">Open Source and Secure <FontAwesomeIcon icon={faLock} className="text-2xl" /></h2>
                    <div className="mt-8 text-gray-600">
                        <p>
                            We understand that security is a top concern when it comes to crypto-related software, and we want to assure our users that our MintyGen Engine is safe and reliable. To avoid any concerns, we have made the source code for the software available on our GitHub page. This means that anyone can view and inspect the code to ensure that it is secure and free from any vulnerabilities. By providing access to the source code, we hope to build trust with our users and demonstrate our commitment to transparency and security.
                        </p>
                        <a className='mt-4 font-bold inline-block' href={GITHUB_URL + '/mintygen-engine'} target='_blank' rel="noreferrer"><span>Link to Repository</span><FontAwesomeIcon icon={faGithub} className="text-lg ml-1" /></a>
                    </div>
                </motion.section>


                <div className="pb-8" />

            </main>

            <footer className="w-full bg-gray-50 py-8 md:py-4 px-8">
                <div className="mx-auto text-center text-gray-600">
                    <p>© 2023 MintyGen. All rights reserved.</p>
                    <p>MintyGen developed by <a href={GITHUB_URL} rel="noreferrer" target='_blank' className='hover:underline'>Brandon Hann</a></p>
                </div>
            </footer>

        </div>
    );
};