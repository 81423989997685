import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ethers } from "ethers";

import { CONTRACT_ADDRESS } from "../App";
import CONTRACT_ABI from "../eth/abi.json";
import "../css/Buy.css";
const key = "/images/spinning-key.gif";
const ftmIcon = "/images/ftm.svg";
// const maticIcon = "/images/matic.svg";
// const binanceIcon = "/images/binance.svg";
const FANTOM_CONTRACT_LINK = "https://ftmscan.com/address/0x93056c5135b17dfa45099dbc448d9bd458d4840d#code";
// const POLYGON_CONTRACT_LINK = "https://polygonscan.com/address/0x93056c5135b17dfa45099dbc448d9bd458d4840d#code";
// const BINANCE_CONTRACT_LINK = "https://bscscan.com/address/0x93056c5135b17dFA45099dbc448d9bd458d4840d#code";

export default function Buy(): JSX.Element {
    const [minted, setMinted] = useState(false);
    const [totalSupply, setTotalSupply] = useState<number | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        const FetchTotalSupply = async () => {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);
                const currentTotalSupply = await contract.totalSupply();
                setTotalSupply(currentTotalSupply.toNumber());
            } else {
                setTotalSupply(null);
            }
        };

        FetchTotalSupply();
        CheckOwnership();

        const HandleAccountsChanged = (accounts: string[]) => {
            if (accounts.length === 0) {
                console.log('Please connect to MetaMask.');
            } else {
                CheckOwnership();
            }
        };

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', HandleAccountsChanged);
        }

        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener('accountsChanged', HandleAccountsChanged);
            }
        };
    }, []);

    const MintNFT = async () => {
        if (window.ethereum) {
            try {
                const [account] = await window.ethereum.request({ method: "eth_requestAccounts" });
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

                const tokenPrice = await contract.tokenPrice();

                const userBalance = await provider.getBalance(account);
                if (userBalance.lt(tokenPrice)) {
                    setErrorMessage("Insufficient funds to mint this NFT.");
                    return;
                }

                const estimatedGas = await contract.estimateGas.mintToken(account, {
                    value: tokenPrice,
                });

                const transaction = await contract.mintToken(account, {
                    value: tokenPrice,
                    gasLimit: Math.ceil(estimatedGas.toNumber() * 1.1), // Adding a 10% buffer to the estimated gas
                });

                await transaction.wait();

                const currentTotalSupply = await contract.totalSupply();
                setTotalSupply(currentTotalSupply.toNumber());
            } catch (error) {
                if ((error as any).code === -32000) {
                    setErrorMessage("Insufficient funds to cover the transaction cost.");
                } else {
                    console.error("Error minting NFT:", error);
                }
            }
        }
    };

    const CheckOwnership = async () => {
        if (window.ethereum) {
            try {
                const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);

                const balance = await contract.balanceOf(account);

                if (balance.gt(0)) {
                    setMinted(true);
                }
            } catch (error) {
                console.error("Error checking NFT ownership:", error);
            }
        } else {
            setErrorMessage("Please install MetaMask.");
        }
    };

    return (
        <div className="bg h-screen flex flex-col items-center justify-center">

            <h1 className="text-2xl md:text-4xl text-center">Mint Your License Key NFT</h1>
            <div className="relative my-8 transition-all duration-300 transform md:hover:scale-110 md:hover:shadow-2xl">
                <div className="absolute top-2 right-2 flex flex-row items-center gap-1 text-sm md:text-xl mb-4 rounded-md border border-gray-300 shadow-sm py-1 px-2 bg-gray-100 cursor-default"><p>85</p> <img className="w-5 h-5" src={ftmIcon} alt="Fantom Logo" /></div>
                {/*
        <div className="absolute top-2 right-2 flex flex-row items-center gap-1 text-sm md:text-xl mb-4 rounded-md border border-gray-300 shadow-sm py-1 px-2 bg-gray-100 cursor-default"><p>30</p> <img className="w-5 h-5" src={maticIcon} alt="Polygon Logo" /></div>
         <div className="absolute top-10 md:top-14 right-2 flex flex-row items-center gap-1 text-sm md:text-xl mb-4 rounded-md border border-gray-300 shadow-sm py-1 px-2 bg-gray-100 cursor-default"><p>0.1</p> <img className="w-5 h-5" src={binanceIcon} alt="Binance Logo" /></div> */}
                <img src={key} className="w-44 h-44 md:w-64 md:h-64 mx-auto md:mt-0 bg-opacity-30 bg-blue-400 border border-gray-400 shadow-lg rounded-md" alt="Spinning key" />
            </div>
            <div className="flex flex-row items-center gap-4">
                <button
                    className={`custom-button bg-blue-500 border-b-2 border-blue-700 active:bg-blue-700 hover:bg-blue-600 shadow-md text-blue-50 px-5 py-2 rounded-md ${minted ? "cursor-not-allowed" : "hover:bg-blue-600"
                        }`}
                    onClick={MintNFT}
                    disabled={minted}
                >
                    {minted ? "Already Minted" : "Mint NFT"}
                </button>
                <p className="text-xl text-center mb-2">
                    {totalSupply === null ? "Loading..." : `${totalSupply} / ∞`}
                </p>
            </div>

            <div className="mt-4">
                <p className={`error-message ${errorMessage ? "show" : ""}`}>{errorMessage}</p>
            </div>

            <div className="flex flex-row gap-4 mt-4">
                <button
                    className="custom-button bg-red-500 border-b-2 border-red-700 text-red-50 px-5 py-2 rounded-md hover:bg-red-600"
                    onClick={() => navigate('/')}
                >
                    Go Back
                </button>

                <a
                    className="custom-button bg-gray-500 border-b-2 border-gray-700 text-gray-50 px-5 py-2 rounded-md hover:bg-gray-600"
                    href={FANTOM_CONTRACT_LINK}
                    rel="noreferrer"
                    target="_blank"
                >
                    View Contract
                </a>
            </div>

            <div className="w-full md:w-2/3 lg:w-1/2 mt-8 p-4 text-center">
                <p className="text-md font-bold">Disclaimer</p>
                <p className="text-xs md:text-sm">
                    By purchasing an NFT from this platform, you acknowledge and agree that there are no refunds after the
                    transaction is completed. Furthermore, you understand that tokens are not transferable after purchase. Please
                    carefully review your decision before proceeding with the NFT purchase, as all sales are final.
                </p>
            </div>

        </div>
    );
}
