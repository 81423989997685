import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import "../css/App.css";
import "../css/Program.css";

import MainView from "../components/MainView";
import Connected from "../components/Connected";
import Version from "../components/Version";

import Engine from "../components/sidepanel/Engine";
import Config from "../components/sidepanel/Config";

import { DISCORD_URL, INSTAGRAM_URL, X_URL, REDDIT_URL, GITHUB_URL } from "../App";

import {
    faTwitter,
    faGithub,
    faInstagram,
    faDiscord,
    faRedditAlien,
} from "@fortawesome/free-brands-svg-icons";

const icon = "/images/ink.svg";
const engineIcon = "/images/engine.svg";
const configIcon = "/images/config.svg";
const version = "1.0";
const activeTitle = "NFT COMPILER";

// Wrapper Function
export default function Program() {
    const navigate = useNavigate();

    return <ProgramClass navigate={navigate} />;
}

interface ProgramClassState {
    value: string;
}

class ProgramClass extends Component<{ navigate: (path: string) => void }, ProgramClassState> {
    constructor(props: { navigate: (path: string) => void }) {
        super(props);
        this.state = { value: "Config" };
    }

    handleClick = (panel: string) => () => {
        console.log(panel);
        this.setState({
            value: panel,
        });
    };

    componentDidMount() {
        document.title = "MintyGen v." + version;
    }

    render() {
        return (
            <main className="program-body bg-slate-600">
                <section className="icon-panel bg-slate-600 overflow-hidden w-16">
                    <div className="icon-container">
                        <a
                            href="/"
                            className="icon-box box-inherit block rounded-lg mt-2 my-1 mx-1 cursor-pointer"
                        >
                            <img className="icon" src={icon} alt="Ink drop"></img>
                        </a>
                        <div>
                            <div className="icon-section box-inherit mt-4 block text-center">
                                <button
                                    onClick={this.handleClick("Config")}
                                    id="config-button"
                                    className="icon-box box-inherit block rounded-lg mt-2 mx-1 cursor-pointer"
                                >
                                    <img
                                        className="icon c-icon"
                                        src={configIcon}
                                        alt="Config icon"
                                    />
                                </button>
                                <button
                                    id="engine-button"
                                    className="icon-box box-inherit block rounded-lg mt-2 mx-1 cursor-pointer"
                                    onClick={this.handleClick("Engine")}
                                >
                                    <img
                                        className="icon"
                                        src={engineIcon}
                                        alt="Engine icon"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-slate-700 border-x border-slate-500 text-slate-50 w-96 box-inherit">
                    <div className="panel box-inherit bg-slate-700">
                        <div className="explorer-title box-inherit my-4 bg-slate-800 shadow-lg rounded-lg text-xl border border-slate-500">
                            <h6 className="mx-auto uppercase">
                                <span className="title-txt">MintyGen</span> • {activeTitle}
                            </h6>
                        </div>
                        <div className="explorer box-inherit block">
                            <div className="explorer-view block">
                                <div className="explorer-container">
                                    <div className="explorer-content mt-2">
                                        <ChangePanels value={this.state.value} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="main-view">
                    <div className="tabs-wrapper box-inherit block">
                        <div>
                            <div className="tabs-ui bg-slate-700 border-b border-slate-500 shadow-xl">
                                <div className="tabs-fit">
                                    <Version />
                                    <div className="copyright mx-2 my-1 text-xs italic text-slate-400">
                                        &copy; Copyright MintyGen 2023 All Rights Reserved
                                    </div>
                                    <Connected />
                                    <div className="socials-box text-slate-300 mx-4">
                                        <a
                                            className="socials"
                                            href={GITHUB_URL}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faGithub} />
                                        </a>
                                        <a
                                            className="socials"
                                            href={DISCORD_URL}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faDiscord} />
                                        </a>
                                        <a
                                            className="socials"
                                            href={REDDIT_URL}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faRedditAlien} />
                                        </a>
                                        <a
                                            className="socials"
                                            href={X_URL}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </a>
                                        <a
                                            className="socials"
                                            href={INSTAGRAM_URL}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-wrapper">
                        <div className="main-container">
                            <MainView />
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

function ChangePanels(props: { value: string }) {
    console.log(props);
    if (props.value === "Config") {
        return <Config />;
    } else if (props.value === "Engine") {
        return <Engine />;
    } else {
        return <div />;
    }
}

export { version };